import React, { Component } from 'react';

import ExportedPanesHeader from '../components/ExportedPanesHeader';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout/index-noheaderandfooter';

class Page extends Component {
  render() {
    return (
      <Layout background="rgb(53, 53, 70)">
        <SEO
          title="Polypane exported panes"
          description="The browser for building responsive &amp; accessible websites."
          keywords={['']}
          pathname={this.props.location.pathname}
        />
        <PageHeader nobg>
          <ExportedPanesHeader />
        </PageHeader>
      </Layout>
    );
  }
}

export default Page;
