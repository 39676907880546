import React from 'react';

const getCoupon = () => {
  if (typeof window !== 'undefined') {
    const params = new URL(document.location).searchParams;
    if (params.get('coupon')) {
      const coupon = params.get('coupon');
      sessionStorage.coupon = coupon;
    }
  }
};

export default class MainLayout extends React.Component {
  render() {
    getCoupon();
    const { children, page } = this.props;
    return (
      <div style={{ minHeight: '100vh', background: this.props.background || '' }}>
        <main id="main">{children}</main>
      </div>
    );
  }
}
